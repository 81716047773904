import React from 'react';


function NoticePage() {
    return (
        <>
            <article>

                <div class="aaae">
                <h2>Public Advisory: LAKE MONSTERS</h2>
                <p>It has come to the attention of the Tre's Beach Community Trustees that there may be extra-natural beings roaming waters in the vicinity of Tre's Beach.
                </p>
                <p>This information comes via a local fisherman identifying himself as "Squee". Apparently, Squee was angling with his young daughter when the beasts made their presence known. While the veracity of Squee's claims cannot at this point be verified, in the interest of public safety, we've reproduced the full account below.
                </p>
                <p>
                    ------
                </p>
                <p>
                    Squee:<br/><br/>
                    "It was nigh dusk when the girle and I had sette to re-packing our riggings with aims of ambling forth into the summer's aire on a course towards our lodgings. The girle, being of scant age, took to skippeing stones as I gathered our riggings. By-and-by, the girle became excited at some unknown presence in the waters.
                </p>
                <p>
                    Not wanting to protract our homeward course, I advised the girle to cease her hysterics. It was not until later that eve, as the girl sette forth to produce her evening colors, that the gravitee and precaritee of those circumstances witnessed by she became known to me.
                </p>
                <p>
                    The girle's many macabre depictions--reproduced below--sent a series of chilles through my bones as I tooke in the monstrous visages. According to the girle, these very creatures had been spied by she within the cruel waters of the lake.
                </p>
                <p>
                    My revulsion gave way to horror as the girle made known to me that, furthermore, the beasts, through some unkown psychic machinery, had even made known their horrible names to she.
                </p>
                <p>
                    Beware the lake..."
                </p>
                </div>
                <div>
                   <figure>
                   <img src='../hamburger.jpg' alt='the creature' class="ri"/>
                   <figcaption>A beast which presented itself to the girle as "Hamburger."</figcaption>
                    </figure> 

                    <figure>
                   <img src='../squishy.jpg' alt='the creature' class="ri"/>
                   <figcaption>"Squishy the Squishy."</figcaption>
                    </figure> 

                    <figure>
                   <img src='../squishy2.jpg' alt='the creature' class="ri"/>
                   <figcaption>Another view of "Squishy the Squishy" (presumablee in motion).</figcaption>
                    </figure> 

                    <figure>
                   <img src='../tootie-bo-biti.jpg' alt='the creature' class="ri"/>
                   <figcaption>"Tootie-bo-biti".</figcaption>
                    </figure> 
                
                    <figure>
                   <img src='../tootie-bukika.jpg' alt='the creature' class="ri"/>
                   <figcaption>"Tootie-bukika".</figcaption>
                    </figure> 
                
                </div>
            </article>
        </>
    );
}

export default NoticePage