import React from 'react';


function HomePage() {
    return (
        <>
            <article>
                <div>
                <img src='../beach.png' alt='the beach' class="ri"/>
                </div>
                <div class="aaae">
                <h2>Beach Considerations</h2>
                <p>Please consider:
                </p>
                <ul>
                    <li>Not littering</li>
                    <li>Picking up some litter</li>
                </ul>
                </div>
            </article>
        </>
    );
}

export default HomePage