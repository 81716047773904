import './App.css';
// import stores from './data/stores';
// import items from './data/items';
import React from 'react';
import Nav from './components/Nav';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import OrderPage from './pages/OrderPage';
import StoresPage from './pages/StoresPage';
import HomePage from './pages/HomePage';
import NoticePage from './pages/NoticePage';


function App() {

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <h1>Tre's Beach</h1>
          <p>"Enjoy... the beach."</p>
          <Nav />
        </header>


        <main>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/notices">
            <NoticePage />
          </Route>
        </main>
      </Router>
      <footer>
        <p>
        <ul>
        
        <li><a href = "mailto: webmaster@tresbeach.info">Contact</a></li>
        <li>© 2022 Tre's Beach Community Trust</li>
        </ul>
        </p>
        
      </footer>
    </div>
  );
}

export default App;
